@import "vars";
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Poppins:wght@200;400;500;600&display=swap');

:root {
  --rem-desktop: 16px;
  --rem-mobile: 12px;
}

$header-font-family: "Poppins", sans-serif !important;
$body-font-family: "Lato", sans-serif !important;


body {
  font-family: "Lato", sans-serif !important;
}

mat-dialog-container {
  font-family: "Poppins", sans-serif !important;

  h5 {
    font-family: "Poppins", sans-serif !important;
  }
  mat-dialog-content {
    font-family: "Poppins", sans-serif !important;
  }

  .mat-dialog-title {
    font-family: "Poppins", sans-serif !important;
  }

  mat-expansion-panel {
    font-family: "Poppins", sans-serif !important;
    mat-expansion-panel-header {
      font-family: "Poppins", sans-serif !important;
      h6 {
        font-family: "Poppins", sans-serif !important;
      }
    }
  }

  mat-checkbox {
    font-family: "Poppins", sans-serif !important;
    span {
      font-family: "Poppins", sans-serif !important;
    }
  }
}

.lausd-secondary {
  color: $lausd-secondary-blue;
}

h1,
h2,
h3,
h4
{
  font-family: "Poppins", sans-serif !important;
}

mat-checkbox {
  label {
    font-family: "Poppins", sans-serif !important;
  }
}


.mat-typography h5 {
  font-family: "Lato", sans-serif !important;
}

/* For desktop */
@media (min-width: 769px) {
  :root {
    font-size: var(--rem-desktop);
  }
}

/* For mobile */
@media (max-width: 768px) {
  :root {
    font-size: var(--rem-mobile);
  }
}