/*!
 *
 * SstAdmin - Bootstrap Admin Template
 *
 * Version: 4.8.1
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

// Bootstrap
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
// Variables
@import "app/variables";
// Layout
@import "app/layout";
@import "app/layout-extra";
@import "app/layout-animation";
@import "app/top-navbar";
@import "app/sidebar";
@import "app/offsidebar";
@import "app/user-block";
@import "app/settings";
// Common
@import "app/typo";
@import "app/bootstrap-reset";
@import "app/bootstrap-custom";
@import "app/button-extra";
@import "app/placeholder";
@import "app/cards";
@import "app/circles";
@import "app/dropdown-extra";
@import "app/half-float";
@import "app/animate";
@import "app/slim-scroll";
@import "app/inputs";
@import "app/utils";
@import "app/print";

@import "themes/theme-a.scss";
@import "vars";

h2 {
  .title {
    color: #002575;
  }
}

a.link {
  color: $link-color;

  &.link--goback {
    text-decoration: none;
    cursor: pointer !important;
    display: inline-flex;
    align-items: center;
    &:hover {
      opacity: 0.8;
    }
  }
}

.card {
  border-radius: 1rem;
  .card-img,
  .card-img-top {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
}

//Home Page
app-home-page {
  height: 100%;
}

.home-page {
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.background-tint {
  background-color: rgba($color: $lausd-primary, $alpha: 0.4);
  background-blend-mode: multiply;
}

//Compare Page
.filter-container {
  .badge.filter-badge {
    padding: 0.5rem 0.75rem;
    background-color: #fff !important;
    color: $lausd-secondary !important;
    font-size: 0.75rem;
    display: inline-flex;
    align-items: center;
    margin-right: 1rem;
    i {
      font-size: 1.2rem;
    }
  }

  .clear-filter-link {
    color: $lausd-secondary-blue;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    &:hover {
      opacity: 0.8;
    }
  }
}

//School Profile Sections
section.school-profile-section {
  margin-bottom: 20px;
  h2 {
    display: inline-flex;
    > .icon {
      padding: 0.5em 0.5em 0.5em 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 100%;
    }

    > .title {
      &.section-title {
        color: #555555;
        margin-top: 5px;
      }
    }
  }

  .section-container {
    ul {
      // padding-left: 1rem;
    }
  }

  .card--profile-section {
    background-color: #ffffff;
    border-radius: 1em;
    position: relative;
    height: 100%;
    box-shadow: $box-shadow;
    p {
      margin-bottom: 0;
      color: #002575;
      font-size: 1.2em;
      line-height: 1.5rem;
    }

    > h5 {
      color: $lausd-secondary-blue;
      font-weight: bold;
      font-size: 1rem;
      margin-bottom: 10px;
    }
    div {
      word-wrap: break-word;
    }

    //Tooltips
    app-tooltip {
      i {
        color: rgb(187 184 184);
        font-size: 1.3rem;
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    //Related Schools Cards
    .card {
      border-radius: 1em;

      > img {
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
      }

      h5 {
        font-size: 1em;
      }

      p {
        font-size: 0.9em;
      }

      .icon {
        color: $lausd-orange;
      }

      .info-text {
        font-size: 0.9em;
      }
    }
  }

  .chart {
    .card--profile-section {
      border-radius: 0 !important;

      > h5 {
        color: #002575;
        font-weight: normal;
        width: 95%;
      }
    }
  }

  app-chart-tile,
  app-chart-demographic-tile {
    height: 100%;
    display: block;
    > div {
      height: 100%;
    }
  }
}

.icon-favorite {
  cursor: pointer;

  &:hover {
    color: $lausd-secondary-blue;
  }

  &.favorited {
    color: $lausd-secondary-blue;
  }
}

//Button Overrides

.btn-primary {
  background-color: $lausd-secondary;
  border-color: $lausd-secondary;
  color: #fff;
  &:hover {
    background-color: $lausd-secondary;
    border-color: $lausd-secondary;
    color: #fff;
    opacity: 0.9;
  }

  &:active {
    background-color: $lausd-secondary !important;
    border-color: $lausd-secondary !important;
    color: #fff !important;
    opacity: 1 !important;
  }
}

.btn-secondary {
  background-color: $lausd-secondary-blue;
  border-color: $lausd-secondary-blue;
  color: #fff;

  &:hover {
    background-color: $lausd-secondary-blue;
    border-color: $lausd-secondary-blue;
    color: #fff;
    opacity: 0.9;
  }

  &:active {
    background-color: $lausd-secondary !important;
    border-color: $lausd-secondary !important;
    color: #fff !important;
    opacity: 1 !important;
  }
}

.btn-warning {
  background-color: $lausd-orange;
  border-color: $lausd-orange;
  color: #fff;

  &:hover {
    background-color: $lausd-orange;
    border-color: $lausd-orange;
    color: #fff;
    opacity: 0.9;
  }

  &:active {
    background-color: $lausd-orange !important;
    border-color: $lausd-orange !important;
    color: #fff !important;
    opacity: 1 !important;
  }
}

.btn-light {
  background-color: #fff;
  border: 1px solid #ced4da;

  &:hover {
    background-color: rgba($color: #fff, $alpha: 0.1);
  }
}

.export-btn.btn-secondary:disabled {
  background-color: rgba($color: $lausd-primary, $alpha: 0.5);
  &:hover {
    border-color: rgba($color: $lausd-primary, $alpha: 0.5);
  }
  &:active {
    background-color: rgba($color: $lausd-primary, $alpha: 0.75) !important;
  }
}

.menu-btn {
  border: none;
  position: absolute;
  right: 0;
  margin-right: 0.5rem;

  &:hover {
    opacity: 0.8;
  }
}

//NGX Dropdown

//Select override
select.select {
  max-height: 42px;
  max-width: 120px;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;

  &:active {
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }
}

@media (max-width: 428px) {
  .drop-options {
    padding: 1rem !important;
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
    // box-shadow: 0 -3px 4px rgba(0, 0, 0, 0.18) !important;
  }
}

//Dialog

.mat-dialog-container {
  padding: 0 !important;

  mat-dialog-content {
    padding: 1rem;
    padding: 0px 48px !important;
    max-height: 520px !important;
  }

  .mat-dialog-title {
    margin: 0 !important;
  }
}

h5.dialog-header {
  margin-bottom: 0;
}

//Buttons

.remove-filter-btn {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.clear-btn {
  background: #f3efef;
  border-radius: 100%;
  height: 1rem;
  width: 1rem;
  text-align: center;
}

//Textarea
textarea {
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #ced4da;

  &:focus {
    outline-color: $lausd-secondary-blue;
  }
}

app-map-dialog {
  mat-dialog-content {
    height: 100%;
    padding: 0 !important;
    section {
      height: 100%;
      google-map {
        height: 100%;
        & > div {
          width: 100% !important;
          height: 65vh !important;
        }
      }
    }
  }
}

@media (max-width: 428px) {
  app-map-dialog {
    mat-dialog-content {
      section {
        google-map {
          & > div {
            height: 75vh !important;
          }
        }
      }
    }
  }
}

// Global Classes

.primary-z {
  z-index: 9999;
}

.grades-modal-width {
  max-width: 90vw!important;
  width: 90vw;
}

.custom-select {
  font-size: 0.75rem;
}

.text-small {
  font-size: 0.75rem;
}

.c-zoom {
  cursor: zoom-in;
}

.input-group {
  align-items: center;
}

.mobile-icon {
  font-size: 1.25rem;
}

.c-pointer {
  cursor: pointer;
}

.text-link {
  color: $link-color;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.PendingCss {
  background-color: #ff902b;

  &.border {
    border-left: 8px solid #ff902b !important;
  }
}

.ApprovedCss {
  background-color: #00602d;

  &.border {
    border-left: 8px solid #00602d !important;
  }
}

.RejectedCss {
  background-color: #ff4d00;

  &.border {
    border-left: 8px solid #ff4d00 !important;
  }
}

.mb-10 {
  margin-bottom: 10px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mt-20 {
  margin-top: 20px !important;
}

.box-shadow {
  box-shadow: $box-shadow;
}

.close-modal-btn {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  opacity: 0.75;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}

//Cards

.card-container {
  background-color: #fff;
  border-radius: 1rem;
  padding: 1rem;

  .card-container-title {
    display: flex;
    justify-content: center;
    flex-direction: column;

    h4 {
      text-align: center;
      margin-bottom: 0;
      font-weight: bold;
    }

    h4 + span {
      text-align: center;
    }
  }

  .card-container-body {
    display: flex;
    flex-direction: column;

    div.submission-content {
      border-bottom: 1px solid #143f6930;
      padding-bottom: 1rem;
      padding-top: 1rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    span.content-update {
      color: #343a40;
    }
  }

  .card-container-footer {
    padding-top: 1rem;
    text-align: center;

    span {
      color: $lausd-secondary-blue;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.diff {
  ins {
    background-color: yellow;
  }

  del {
    background-color: red;
  }
}

// Loader Override
.spinner-border {
  width: 1rem;
  height: 1rem;
}

//Inputs

input[type="text"].truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

span.placeholder-ref {
  display: none;
}

//Shadows
.shadow-custom {
  box-shadow: $box-shadow;
}

.search-shadow {
  box-shadow: 0px 2px 9px 2px rgb(4 17 93 / 9%) !important;
}

#gmimap0 {
  visibility: hidden;
}

// School Profile

.hide-list {
  overflow: hidden;
  max-height: 5rem;
}

// Search Override
ng-dropdown-panel.ng-dropdown-panel {
  background-color: $lausd-gray-6;
  top: 3.75rem;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 0.75rem 0.75rem rgba(0, 0, 0, 0.03) !important;
  overflow-y: auto;
  max-height: 45vh;

  .dropdown-group-title {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .instruction {
    color: $lausd-primary;
    margin-bottom: 0.5rem;
    display: inline-block;
  }

  .dropdown-keyword {
    display: flex;
    flex-direction: column;
    > span {
      font-size: 0.75rem;
      color: $lausd-primary;
      margin-bottom: 0.5rem;
    }
    i {
      color: $lausd-primary;
    }
    div {
      padding: 0.25rem 0.5rem;
      color: #000;
      &:hover {
        background-color: #fff;
        border-radius: 0.25rem;
        cursor: pointer;
      }
    }
  }

  .dropdown-item {
    padding: 0.25rem 0.5rem;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    i {
      color: $lausd-primary;
    }

    &:hover {
      background-color: #fff;
      border-radius: 0.25rem;
      color: $lausd-gray-1;
    }
  }
}
.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 80rem !important;
}

@media (max-width: 428px) {
  .home-page-mobile {
    ng-dropdown-panel.ng-dropdown-panel {
      top: 4.7rem;
      max-height: unset;
      height: 60vh;
      width: 100vw;
      left: -1rem;
      background-color: #fff;
      // box-shadow: 0 -3px 4px rgba(0, 0, 0, 0.18) !important;

      .ng-dropdown-panel-items {
        padding-bottom: 14rem;
      }
    }
  }

  .keyword-mobile,
  .location-mobile {
    ng-dropdown-panel.ng-dropdown-panel {
      top: 4.7rem;
      max-height: unset;
      height: 76vh;
      width: 100vw;
      left: -1.15rem;
      // box-shadow: 0 -3px 4px rgba(0, 0, 0, 0.18) !important;
      background-color: #fff;
      border-radius: 1rem;
      .ng-dropdown-panel-items {
        padding-bottom: 14rem;
      }
    }
  }
}

// Tour Classes
.tour-element {
  &.bottom {
    margin-top: 1rem;
  }
  &.top {
    margin-bottom: 2rem;
  }
  &.tour-shortcut {
    left: auto !important;
    right: 3rem !important;
  }
}

@media (max-width: 428px) {
  .shepherd-element.tour-element {
    transform: scale(0.8) !important;
  }

  .tour-element {
    &.tour-shortcut {
      left: 0px !important;
    }
  }
}

// Modal Class
@media (max-width: 428px) {
  // .swal-modal {
  //   // transform: scale(0.8)!important;
  //   height: 100vh!important;
  //   width: 100vw!important;
  //   position: absolute!important;
  //   top: -1.25rem!important;
  //   left: 0!important;
  //   display: flex;
  //   flex-direction: column;

  //   .swal-footer {
  //     margin-top: auto!important;
  //   }
  // }
  .cdk-overlay-container {
    z-index: 9999 !important;
  }
}
.cdk-overlay-container {
  z-index: 3000;
}

.modal-index {
  z-index: 9999 !important;
}

.mobile-modal {
  min-height: 100%;
  min-width: 100vw;
  background-color: #fff;

  .dialog-header {
    background-color: #fff !important;
  }
  mat-dialog-content {
    max-height: 80vh;
    background-color: #fff !important;
  }
}

.mobile-padding-top {
  padding-top: 2rem !important;
}

.mobile-map-modal {
  height: 95vh !important;
  min-width: 100vw !important;

  mat-dialog-content {
    max-height: 95vh;
  }

  app-map-dialog mat-dialog-content section google-map > div {
    max-height: 90vh !important;
  }
}


.border-radius {
  border-radius: 1rem !important;
  overflow: hidden;
}


// Demographics colors
.bg-twoOrMore {
  background-color: #fb8f87;
}

.bg-notReported {
  background-color: #84acfb;
}

.bg-asian {
  background-color: #49f27f;
}
