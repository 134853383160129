/* You can add global styles to this file, and also import other style files */

// == Vendor
// @import '~@fortawesome/fontawesome-free/css/brands.css';
// @import '~@fortawesome/fontawesome-free/css/regular.css';
// @import '~@fortawesome/fontawesome-free/css/solid.css';
// @import '~@fortawesome/fontawesome-free/css/fontawesome.css';
// @import '~simple-line-icons/css/simple-line-icons.css';

//== Bootstrap
@import "./app/shared/styles/bootstrap.scss";
//== Application
@import "./app/shared/styles/app.scss";
//== Swiper.js

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.p-10 {
    padding: 10px
}

.p-20 {
    padding: 20px;
}