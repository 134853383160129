$lausd-primary: #002575;
$lausd-secondary: #0089FF;
$lausd-warning: #FF4D00;
$lausd-orange: #FF4D00;
$lausd-danger: #FF0000;
$lausd-green: #00602D;
$lausd-success: #00602D;
$lausd-yellow: #ff9C00;
$lausd-purple: #673AB7;
$lausd-light-green: #7BC2B2;
$lausd-dark-green: #008063;

$lausd-secondary-blue: #005FD3;
$lausd-secondary-blue-rgb: rgb(0, 95, 211);

$lausd-yellow: #FF0000;

$gray-darker: #232735;
$gray-dark: #3a3f51;
$gray: #949a9c;
$gray-light: #c5cacc;
$gray-lighter: #eceef1;


$box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18) !important;

$dialog-border-radius: .25rem;


$link-color: #005FD3;
$lausd-blue-1: #005FD3;
$lausd-blue-2: #009DDB;
$lausd-blue-3: #5B91D8;
$lausd-blue-4: #ABDEFF;
$lausd-blue-5: #B3D4FF;
$lausd-blue-6: #D0DDFF;
$lausd-blue-7: #DCF0FF;


$lausd-gray-1: #333F49;
$lausd-gray-2: #4E6267;
$lausd-gray-3: #80898C;
$lausd-gray-4: #9EA3A4;
$lausd-gray-5: #C0C1C3;
$lausd-gray-6: #e1e1e5;

$h-blue: #00237a;
$link-blue: #0289FF;